<template>
  <div v-if="personalData === 'personalData'">
    <h4 class="mb-3" style="text-align: center;">¡Estás a unos pasos de disfrutar de grandes beneficios! Cuéntanos un poco de tí para comenzar.</h4>
  </div>
  <div class="verify-carpin-club">
    <h1 class="mb-4">Registro CarpinClub</h1>
    <form @submit.prevent="datos">
      <div class="card mb-4" v-if="personalData === 'personalData'">
        <div class="card-header bg-primary text-white">
          <h2 class="h5 mb-0">Datos Personales</h2>
        </div>
        <div class="card-body">
          <div class="mb-3">
            <label for="firstName" class="form-label">Nombre *</label>
            <input type="text" class="form-control" id="firstName" v-model="firstName" required>
          </div>
          <div class="mb-3">
            <label for="lastName" class="form-label">Apellido *</label>
            <input type="text" class="form-control" id="lastName" v-model="lastName" required>
          </div>
          <div class="mb-3">
            <label for="role" class="form-label">Rol o función *</label>
            <select class="form-select" id="role" v-model="role" required>
              <option value="" disabled>Selecciona una opción</option>
              <option value="Compras">Compras</option>
              <option value="Diseño">Diseño</option>
              <option value="Propietario/Gerente">Propietario/Gerente</option>
              <option value="Ventas">Ventas</option>
              <option value="Administrativo/Contable">Administrativo/Contable</option>
            </select>
          </div>
          <div class="mb-3">
            <label for="cellPhone" class="form-label">Celular comercial *</label>
            <input type="tel" class="form-control" id="cellPhone" v-model="cellPhone" @input="validateCellPhone" :class="{ 'is-invalid': !isValidCellPhone && cellPhone !== '' }" placeholder="3232323232" required>
            <div class="invalid-feedback" v-if="!isValidCellPhone && cellPhone !== ''">
              Por favor ingresa un número de celular válido.
            </div>
          </div>
          <div class="mb-3">
            <label for="email" class="form-label">Correo comercial *</label>
            <input type="email" class="form-control" id="email" v-model="email" :class="{ 'is-invalid': !isValidEmail && email !== '' }" placeholder="example@example.com" required>
          </div>
          <div class="invalid-feedback" v-if="!isValidEmail && email !== ''">
            Por favor ingresa una dirección de correo electrónico válida.
          </div>

          <div class="mb-3">
            <label for="birthDate" class="form-label">Fecha de nacimiento *</label>
            <input
              type="date"
              class="form-control"
              id="birthDate"
              v-model="birthDate"
              :min="minDate"
              :max="maxDate"
              :class="{ 'is-invalid': !isValidAge }"
              required
            >
            <div class="invalid-feedback" v-if="!isValidAge">
              La edad debe ser entre 15 y 120 años.
            </div>
          </div>

          <button type="button" class="btn btn-primary" @click="validateAndProceed('personalData', 'companyData')" :disabled="!isPersonalDataValid">Siguiente</button>
        </div>
      </div>

      <div class="card mb-4" v-if="companyData === 'companyData'">
        <div class="card-header bg-primary text-white">
          <h2 class="h5 mb-0">Datos de Negocio</h2>
        </div>
        <div class="card-body">
          <div class="mb-3">
            <label for="companyName" class="form-label">Nombre del negocio *</label>
            <input type="text" class="form-control" id="companyName" v-model="companyName" required>
          </div>
          <div class="mb-3">
            <label for="nit" class="form-label">NIT sin dígito de verificación o Cédula *</label>
            <input type="number" class="form-control" id="nit" v-model="nit" placeholder="2222222222" required>
          </div>
          <div class="mb-3">
            <label for="companyAddress" class="form-label">Dirección del negocio *</label>
            <input type="text" class="form-control" id="companyAddress" v-model="companyAddress" placeholder="Dirección/Municipio" required>
          </div>
          <div class="mb-3">
            <label for="sector" class="form-label">Sector/especialidad que mejor te define *</label>
            <select class="form-select" id="sector" v-model="sector" required>
              <option value="" disabled>Selecciona una opción</option>
              <option value="Arquitecto/Diseñador/Interiorista">Arquitecto/Diseñador/Interiorista</option>
              <option value="Artesanías/Arte Country">Artesanías/Arte Country</option>
              <option value="Ataúdes y Urnas">Ataúdes y Urnas</option>
              <option value="Campamentos">Campamentos</option>
              <option value="Carpintería Arquitectónica Obra">Carpintería Arquitectónica Obra</option>
              <option value="Carpintería Arquitectónica Residencial">Carpintería Arquitectónica Residencial</option>
              <option value="Constructor">Constructor</option>
              <option value="Distribuidor Herrajes/Maderas">Distribuidor Herrajes/Maderas</option>
              <option value="Instalación/Ensamble">Instalación/Ensamble</option>
              <option value="Mobiliario Alta Gama">Mobiliario Alta Gama</option>
              <option value="Mobiliario Comercial">Mobiliario Comercial</option>
              <option value="Mobiliario Hospitalario y/o Laboratorios">Mobiliario Hospitalario y/o Laboratorios</option>
              <option value="Mobiliario RTA">Mobiliario RTA</option>
              <option value="Mobiliario de Oficina y/o Educativo">Mobiliario de Oficina y/o Educativo</option>
              <option value="Mobiliario en Unicor y Mdf">Mobiliario en Unicor y Mdf</option>
              <option value="Publicidad y Stands">Publicidad y Stands</option>
            </select>
          </div>
          <div class="mb-3">
            <label for="city" class="form-label">Ciudad de tu Carpincentro más cercano *</label>
            <select class="form-select" id="city" v-model="city" required>
              <option value="" disabled>Selecciona una opción</option>
              <option value="Bogotá">Bogotá</option>
              <option value="Bucaramanga">Bucaramanga</option>
              <option value="Barranquilla">Barranquilla</option>
              <option value="Cali">Cali</option>
              <option value="Cartagena">Cartagena</option>
              <option value="Duitama">Duitama</option>
              <option value="Girardot">Girardot</option>
              <option value="Ibagué">Ibagué</option>
              <option value="Pereira">Pereira</option>
              <option value="Piedecuesta">Piedecuesta</option>
              <option value="Tunja">Tunja</option>
              <option value="Sogamoso">Sogamoso</option>
            </select>
          </div>
          <div class="mb-3">
            <label for="modularMaterials" class="form-label">¿Compras material modulado? *</label>
            <select class="form-select" id="modularMaterials" v-model="modularMaterials" required>
              <option value="" disabled>Selecciona una opción</option>
              <option value="SI">Si</option>
              <option value="NO">No</option>
            </select>
          </div>
          <div class="mb-3">
            <label for="onlinePurchase" class="form-label">¿Compras productos online o te podría interesar hacerlo (para tu negocio)? *</label>
            <select class="form-select" id="onlinePurchase" v-model="onlinePurchase" required>
              <option value="" disabled>Selecciona una opción</option>
              <option value="SI">Si</option>
              <option value="NO">No</option>
            </select>
          </div>
          <div class="d-flex justify-content-between">
            <!-- <button type="button" class="btn btn-secondary" @click="showNextSection('personalData')">Atrás</button>
            <button type="button" class="btn btn-primary" @click="showNextSection('communication')">Siguiente</button> -->
            <button type="button" class="btn btn-secondary" @click="showNextSection('personalData')">Atrás</button>
            <button type="button" class="btn btn-primary" @click="validateAndProceed('companyData', 'communication')" :disabled="!isCompanyDataValid">Siguiente</button>
          </div>
        </div>
      </div>

      <div class="card mb-4" v-if="communication === 'communication'">
        <div class="card-header bg-primary text-white">
          <h2 class="h5 mb-0">Comunicación</h2>
        </div>
        <div class="card-body">
          <label class="form-label">Nos gustaria estar en contacto y seguirte, cuéntanos si actualmente tu negocio maneja: </label>
          <div class="mb-3">
            <label class="form-label">Redes sociales del negocio (opcional)</label>
            <div v-for="(entry, index) in socialMediaEntries" :key="index" class="mb-2">
              <div class="input-group">
                <select class="form-select" v-model="entry.red">
                  <option value="" disabled>Selecciona una opción</option>
                  <option value="Instagram">Instagram</option>
                  <option value="Facebook">Facebook</option>
                  <option value="Pinterest">Pinterest</option>
                  <option value="YouTube">YouTube</option>
                  <option value="LinkedIn">LinkedIn</option>
                  <option value="TikTok">TikTok</option>
                  <option value="X (Twitter)">X (Twitter)</option>
                </select>
                <input type="text" class="form-control" v-model="entry.url" placeholder="Nombre de usuario">
              </div>
            </div>
            <button type="button" class="btn btn-outline-secondary btn-sm" @click="addSocialMedia">+ Añadir red social</button>
          </div>
          <div class="mb-3">
            <label for="website" class="form-label">Página web (sólo si tienes)</label>
            <input type="text" class="form-control" id="website" v-model="website">
          </div>
          <div class="mb-3">
            <label for="content" class="form-label">¿Qué tipo de contenido te gusta ver o leer? (opcional)</label>
            <textarea class="form-control" id="content" v-model="content" rows="3"></textarea>
          </div>

          <div class="mb-3">
            <label class="form-label">¿Qué tipo de formato de contenido de valor prefieres? * (Máximo 2 opciones)</label>
            <div class="form-check" v-for="(option, key) in contentOptions" :key="key">
              <input
                class="form-check-input"
                type="checkbox"
                :id="key"
                :value="key"
                v-model="selectedContentOptions"
                @change="handleContentOptionChange(key)"
                :disabled="selectedContentOptions.length >= 2 && !selectedContentOptions.includes(key)"
              >
              <label class="form-check-label" :for="key">{{ option }}</label>
            </div>
          </div>

          <div class="mb-3">
            <label class="form-label">¿En cuál de estas redes o canales preferirías obtener contenido de valor? * </label>
            <select class="form-select" v-model="contenido_prederirias" required>
              <option value="" disabled>Selecciona una opción</option>
              <option value="Whatsapp">Whatsapp</option>
              <option value="Intagram">Intagram</option>
              <option value="Facebook">Facebook</option>
              <option value="Pinterest">Pinterest</option>
              <option value="Youtube">Youtube</option>
              <option value="Tik Tok">Tik Tok</option>
              <option value="Email">Email</option>
              <option value="Linkedin">Linkedin</option>
            </select>
          </div>

          <div class="card-header bg-primary text-white">
            <h2 class="h5 mb-0">Autorizaciones *</h2>
          </div>
          <div class="card-body">
            <div class="mb-3 form-check">
              <input type="checkbox" class="form-check-input" id="dataAuthorization" v-model="dataAuthorization" required>
              <label class="form-check-label" for="dataAuthorization">Acepto el uso de mis datos personales para recibir información de acumulación y redención de mis puntos, y conocer ofertas especiales que Carpincentro y/o Ardisa tienen para mí. *</label>
            </div>
            <div class="mb-3 form-check">
              <input type="checkbox" class="form-check-input" id="consentAuthorization" v-model="consentAuthorization" required>
              <label class="form-check-label" for="consentAuthorization">Al diligenciar el presente documento, autorizo a Carpincentro y/o ARDISA S.A,  identificada con Nit 890.200.050-6 a recopilar mis datos de identificación, rol, celular, correo electrónico y fecha de nacimiento, con la finalidad de ejecutar relación contractual, proveer servicios y productos, enviar información sobre promoción comercial y servicios de la empresa, transferir y transmitir mis datos a sus aliados comerciales, actualizarlos y los demás fines establecidos en la <a href="https://www.ardisa.com/politica-de-datos-personales">Política de Privacidad</a>. Declaro que ARDISA S.A, me ha informado de mis derechos como titular de los datos suministrados (conocer, actualizar, rectificar, suprimir los datos y revocar la autorización), los cuales podrán ser ejercidos a través del correo electrónico habeasdata@ardisa.com.  *</label>
            </div>
            <div class="d-flex justify-content-between">
              <button type="button" class="btn btn-secondary" @click="showNextSection('companyData')">Atrás</button>
              <button type="submit" class="btn btn-primary">Enviar</button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <!-- Modal informativo de celular o correo repetido -->
    <div v-if="Modal_RCC" class="formulario-overlay">
      <div class="formulario-modal" >
        <button class="close-button " v-on:click="this.Modal_RCC=false;">&times;</button>
        <p>
          Bienvenido de vuelta! El cel o correo que ingresaste ya está resgistrado.
        </p>
        <p>
          Si aún no has activado tu cuenta:
          <ol>
            <li>Revisa tu celular y haz clic en el enlace del SMS que te enviamos</li>
            <li>Revisa tu correo y haz clic en el enlace que te enviamos</li>
          </ol>
        </p>
        <p>
          ¿No encuentras los mensajes? Llámanos al 3124800831 y con mucho gusto te ayudaremos
        </p>
        <div style="display: flex; justify-content: flex-end; margin-top: 10px;">
          <a href="https://wa.me/573124800831" target="_blank" class="btn btn-primary" style="text-decoration: none;">Ayúdame</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      personalData: 'personalData',
      companyData: 'personalData',
      communication: 'personalData',
      firstName: '',
      lastName: '',
      role: '',
      cellPhone: '',
      email: '',
      birthDate: '',
      companyName: '',
      nit: null,
      companyAddress: '',
      sector: '',
      city: '',
      socialMediaEntries: [{ red: '', url: '' }],
      website: '',
      modularMaterials: '',
      onlinePurchase: '',
      content: '',
      contenido_prederirias: '',
      Modal_RCC: false,

      selectedContentOptions: [],
      contentOptions: {
        Capacitaciones_virtuales: 'Capacitaciones virtuales',
        Capacitaciones_presenciales: 'Capacitaciones presenciales',
        Videos_cortos_y_rapidos: 'Videos cortos y rápidos',
        Video_tutoriales_especificos: 'Video tutoriales específicos',
        PDFs_o_capacitación_en_texto: 'PDFs o capacitación en texto',
        Podcasts_o_audio_guías: 'Podcasts o audio guías'
      }
    }
  },
  computed: {
    isValidCellPhone () {
      return this.cellPhone.length === 10
    },
    minDate () {
      const date = new Date()
      date.setFullYear(date.getFullYear() - 120)
      return date.toISOString().split('T')[0]
    },
    maxDate () {
      const date = new Date()
      date.setFullYear(date.getFullYear() - 15)
      return date.toISOString().split('T')[0]
    },
    isValidEmail () {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return emailRegex.test(this.email)
    },
    isPersonalDataValid () {
      return this.firstName && this.lastName && this.role &&
             this.isValidCellPhone && this.isValidEmail && this.isValidAge
    },
    isValidAge () {
      if (!this.birthDate) return true // Si no hay fecha, no mostramos error
      const age = this.calculateAge(this.birthDate)
      return age >= 15 && age <= 120
    },
    isCompanyDataValid () {
      return this.companyName && this.nit && this.companyAddress && this.sector && this.city
    },
    isCommunicationDataValid () {
      return this.modularMaterials && this.onlinePurchase && this.dataAuthorization && this.consentAuthorization && this.selectedContentOptions.length > 0
    }
  },
  methods: {
    validateAndProceed (currentSection, nextSection) {
      if (this[`is${currentSection.charAt(0).toUpperCase() + currentSection.slice(1)}Valid`]) {
        if (currentSection === 'personalData' && !this.isValidEmail) {
          alert('Por favor, ingrese una dirección de correo electrónico válida.')
          return
        }
        this.showNextSection(nextSection)
      } else {
        alert('Por favor, complete todos los campos requeridos correctamente antes de continuar.')
      }
    },
    validateCellPhone (event) {
      // Elimina cualquier carácter que no sea un número
      this.cellPhone = this.cellPhone.replace(/\D/g, '')
      // Limita la longitud a 10 dígitos
      if (this.cellPhone.length > 10) {
        this.cellPhone = this.cellPhone.slice(0, 10)
      }
    },
    calculateAge (birthDate) {
      const today = new Date()
      const birthDateObj = new Date(birthDate)
      let age = today.getFullYear() - birthDateObj.getFullYear()
      const monthDiff = today.getMonth() - birthDateObj.getMonth()
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
        age--
      }
      return age
    },
    handleContentOptionChange (option) {
      if (this.selectedContentOptions.includes(option)) {
        this[option] = true
      } else {
        this[option] = false
      }
    },
    showNextSection (nextSectionId) {
      this.personalData = nextSectionId
      this.companyData = nextSectionId
      this.communication = nextSectionId
    },
    addSocialMedia () {
      this.socialMediaEntries.push({ red: '', url: '' })
    },
    datos () {
      if (!this.isCommunicationDataValid) {
        alert('Por favor, complete todos los campos requeridos antes de enviar.')
      } else {
        const birthDate1 = new Date(this.birthDate)
        const today = new Date()
        let age = today.getFullYear() - birthDate1.getFullYear()
        const monthDiff = today.getMonth() - birthDate1.getMonth()
        const dayDiff = today.getDate() - birthDate1.getDate()

        if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
          age--
        }

        if (age < 15 || age > 120) {
          alert('Debe tener mínimo 15 años o máximo 120 años')
          this.showNextSection('personalData')
        } else if (this.cellPhone.length !== 10) {
          alert('El teléfono debe tener diez (10) dígitos')
          this.showNextSection('personalData')
        } else if (String(this.nit).length < 6 || String(this.nit).length > 10) {
          alert('El NIT o cédula debe tener entre seis (6) y diez (10) dígitos')
          this.showNextSection('companyData')
        } else {
          const datosEnviar = {
            firstName: this.firstName,
            lastName: this.lastName,
            role: this.role,
            cellPhone: this.cellPhone,
            email: this.email,
            birthDate: this.birthDate,
            companyName: this.companyName,
            nit: this.nit,
            companyAddress: this.companyAddress,
            sector: this.sector,
            city: this.city,
            socialMediaEntries: JSON.stringify(this.socialMediaEntries),
            website: this.website,
            modularMaterials: this.modularMaterials,
            onlinePurchase: this.onlinePurchase,
            content: this.content,
            dataAuthorization: this.dataAuthorization,
            consentAuthorization: this.consentAuthorization,
            Podcasts_o_audio_guías: this.Podcasts_o_audio_guías,
            PDFs_o_capacitación_en_texto: this.PDFs_o_capacitación_en_texto,
            Video_tutoriales_especificos: this.Video_tutoriales_especificos,
            Videos_cortos_y_rapidos: this.Videos_cortos_y_rapidos,
            Capacitaciones_presenciales: this.Capacitaciones_presenciales,
            Capacitaciones_virtuales: this.Capacitaciones_virtuales
          }
          console.log(datosEnviar)
          console.log(this.selectedContentOptions)
          fetch('https://app.carpincentro.com/funciones_carpinclub/insertarDatos.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(datosEnviar)
          })
            .then((response) => response.json())
            .then((data) => {
              console.log('Respuesta del servidor:', data)
              if (data.Listo === 'Registro listo') {
                this.$router.push('/verif-carpinclub/datos')
              } else if (data.duplicado === 'Teléfono o correo electrónico ya están registrados') {
                // alert('Bienvenido de vuelta! El cel o correo que ingresaste ya está registrado. ')
                this.showNextSection('personalData')
                this.Modal_RCC = true
              } else {
                console.log('Hubo un error en el registro')
              }
            })
            .catch((error) => {
              console.error('Error al actualizar los datos:', error)
            })
        }
      }
    }
  },
  watch: {
    selectedContentOptions (newVal) {
      if (newVal.length > 2) {
        this.selectedContentOptions = newVal.slice(0, 2)
      }
    }
  }
}
</script>

<style scoped>
.verify-carpin-club {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  color: var(--primary-color);
}

.card-header {
  background-color: var(--primary-color) !important;
}

.btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-primary:hover {
  background-color: #161f3d;
  border-color: #161f3d;
}

.btn-outline-secondary {
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-outline-secondary:hover {
  background-color: var(--primary-color);
  color: white;
}

.is-invalid {
  border-color: #dc3545;
}

.invalid-feedback {
  color: #dc3545;
  display: block;
  margin-top: 0.25rem;
}

.fecha-cumple-wrapper {
    display: flex;
    gap: 10px;
}

.formulario-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.formulario-modal{
  background-color: white;
  padding: 1%;
  border-radius: 20px;
  width: 50%; /* Ancho del 80% de la pantalla */
  max-height: 50%; /* Altura máxima del modal: 80% del alto de la pantalla */
  overflow-y: auto; /* Habilita la barra de desplazamiento vertical cuando el contenido excede la altura */
  overflow-x: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  position: relative;
  border: none;
  color: #000;
}

.close-button {
  position: absolute;
  top: 0px;
  right: 1px;
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
  color: #333;
}

.close-button:hover {
  border-radius: 50px;
  color: #000;
  background-color: #dc3545;
}
</style>
