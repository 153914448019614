<template>
  <div class="verify-email">
    <h3 class="tipo_letra mb-4" >Felicitaciones! Ya eres parte de Carpinclub, donde siempre ganas</h3>
    <div v-if="loading" class="tipo_letra text-center">
      <div class="tipo_letra spinner-border text-primary" role="status">
        <span class="visually-hidden">Cargando...</span>
      </div>
      <p class="tipo_letra mt-2">Cargando...</p>
    </div>
    <div v-else class="tipo_letra alert" :class="{ 'alert-success': !registro, 'alert-warning': registro }">
      <p v-if="registro === false" class="tipo_letra mb-0">
        Estamos emocionados de tenerte con nosotros
      </p>
      <p v-if="registro === true" class="tipo_letra mb-0">
        Este correo electrónico ya está registrado y verificado.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      email: '',
      token: '',
      registro: null,
      loading: true
    }
  },
  created () {
    this.email = this.$route.params.email
    this.token = this.$route.params.token
    this.ejecutarFuncion(this.email, this.token)
  },
  methods: {
    ejecutarFuncion (email, token) {
      console.log('email recibido:', email)
      console.log('token recibido:', token)
      const formData = {
        token1: email,
        token2: token
      }
      fetch('https://app.carpincentro.com/funciones_carpinclub/validaremail.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('Respuesta del servidor:', data)
          this.registro = data.Listo === 'Ya esta registrado'
          this.loading = false
        })
        .catch((error) => {
          console.error('Error al actualizar los datos:', error)
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>
.verify-email {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  color: var(--primary-color);
}

.spinner-border {
  width: 3rem;
  height: 3rem;
}

.tipo_letra{
  font-family: 'Futura'
}
</style>
